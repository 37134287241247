<template>
  <div class="container-fluid main-color">
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box">
          <h4 class="page-title">
            {{
              currentRouteName == "increment-management-request-update"
                ? "Edit Record"
                : "Add Record"
            }}
          </h4>
        </div>
      </div>
    </div>
    <!-- end page title -->
    <div class="row">
      <div class="col-lg-12">
        <place-holder v-if="loading"></place-holder>
        <div class="card">
          <div v-if="!loading" class="card-body">
            <form class="parsley-examples">
              <div class="row">
                <div class="col-md-7">
                  <div class="row mb-3">
                    <label for="name" class="col-md-3 form-label">
                      Program Name
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'increment-management-create',
                          'increment-management-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedProgram"
                        v-model="selectedProgram"
                        label="name"
                        :disabled="
                          currentRouteName ==
                          'increment-management-request-update'
                        "
                        :options="programs"
                        :selectable="(options) => isSelectableProgram(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedProgram.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedProgram.$errors,
                          value: 'Program Name',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="hasPermissions(['increment-management-list'])"
                    >
                      <v-select
                        v-model="selectedProgram"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Batch
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'increment-management-create',
                          'increment-management-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedBatch"
                        v-model="selectedBatch"
                        label="batch_no"
                        :disabled="
                          currentRouteName ==
                          'increment-management-request-update'
                        "
                        :options="batch"
                        :selectable="(options) => isSelectableBatch(options)"
                        :clearable="false"
                        :class="{
                          'p-invalid':
                            v$.selectedBatch.$error || errorFor('name'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('name')"
                        :vuelidateErrors="{
                          errors: v$.selectedBatch.$errors,
                          value: 'Batch',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="hasPermissions(['increment-management-list'])"
                    >
                      <v-select
                        v-model="selectedBatch"
                        label="batch_no"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>

                  <div class="row mb-3">
                    <label for="short_name" class="col-md-3 form-label">
                      Level
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-8"
                      v-if="
                        hasPermissions([
                          'increment-management-create',
                          'increment-management-edit',
                        ])
                      "
                    >
                      <v-select
                        @option:selected="getSelectedLevel"
                        v-model="selectedLevel"
                        label="name"
                        :disabled="
                          currentRouteName ==
                          'increment-management-request-update'
                        "
                        :options="levels"
                        :clearable="false"
                        :selectable="(options) => isSelectable(options)"
                        :class="{
                          'p-invalid':
                            v$.incrementData.level.$error || errorFor('level'),
                        }"
                      >
                      </v-select>
                      <v-errors
                        :serverErrors="errorFor('level')"
                        :vuelidateErrors="{
                          errors: v$.incrementData.level.$errors,
                          value: 'Level',
                        }"
                      ></v-errors>
                    </div>

                    <div
                      class="col-md-8"
                      v-else-if="hasPermissions(['increment-management-list'])"
                    >
                      <v-select
                        v-model="selectedLevel"
                        label="name"
                        disabled
                        :clearable="false"
                      >
                      </v-select>
                    </div>
                  </div>
                </div>

                <div class="col-md-5">
                  <div class="row mb-3">
                    <label for="name" class="col-md-4 form-label">
                      Effective Date
                      <span class="text-danger">*</span>
                    </label>
                    <div
                      class="col-md-7"
                      v-if="
                        hasPermissions([
                          'increment-management-create',
                          'increment-management-edit',
                        ])
                      "
                    >
                      <flat-pickr
                        class="form-control"
                        placeholder="Select Date"
                        v-model="effectiveDate"
                        :class="{
                          'p-invalid':
                            v$.effectiveDate.$error ||
                            errorFor('Effective Date'),
                        }"
                      >
                      </flat-pickr>
                      <v-errors
                        :serverErrors="errorFor('Effective Date')"
                        :vuelidateErrors="{
                          errors: v$.effectiveDate.$errors,
                          value: 'Effective Date',
                        }"
                      >
                      </v-errors>
                    </div>

                    <div
                      class="col-md-7"
                      v-else-if="hasPermissions(['increment-management-list'])"
                    >
                      <flat-pickr
                        class="form-control"
                        placeholder="Select Date"
                        v-model="effectiveDate"
                        disabled
                      >
                      </flat-pickr>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="mt-3">
                <table class="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Employee Name</th>
                      <th>Employee ID</th>
                      <th>Joined Date</th>
                      <th>BU/BR/DIV</th>
                      <th>Grade</th>
                      <th>Related COO</th>
                      <th>Remark</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in gradingDetail" :key="index">
                      <td>{{ ++index }}</td>
                      <td>
                        {{ item.grading_evaluation_detail.employee_name }}
                      </td>
                      <td>{{ item.grading_evaluation_detail.employee_id }}</td>
                      <td>{{ item.grading_evaluation_detail.join_date }}</td>
                      <td>{{ item.business_unit.name }}</td>
                      <td>{{ item.grade }}</td>
                      <td
                        v-if="
                          hasPermissions([
                            'increment-management-create',
                            'increment-management-edit',
                          ])
                        "
                      >
                        <v-select
                          :options="employees"
                          label="name"
                          :clearable="false"
                          style="width: 160px"
                          v-model="item.r_coo_id"
                          :selectable="
                            (options) => options.id != item.r_coo_id?.id
                          "
                          :disabled="item.r_coo_status == 1"
                          :class="{
                            'p-invalid': item.r_c_error,
                          }"
                        >
                        </v-select>
                        <p class="text-danger" v-if="item.r_c_error">
                          Related COO is required.
                        </p>
                      </td>
                      <td
                        v-else-if="
                          hasPermissions(['increment-management-list'])
                        "
                      >
                        <v-select
                          :options="employees"
                          label="name"
                          :clearable="false"
                          style="width: 160px"
                          v-model="item.r_coo_id"
                        >
                        </v-select>
                      </td>
                      <td
                        v-if="
                          hasPermissions([
                            'increment-management-create',
                            'increment-management-edit',
                          ])
                        "
                      >
                        <input
                          type="text"
                          class="form-control w-75"
                          v-model="item.remark"
                        />
                      </td>
                      <td
                        v-else-if="
                          hasPermissions(['increment-management-list'])
                        "
                      >
                        <input
                          type="text"
                          class="form-control w-75"
                          v-model="item.remark"
                          disabled
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div
                class="progress mt-3"
                style="height: 0.5px; background-color: #ccc !important"
              >
                <div class="progress-bar" role="progressbar"></div>
              </div>

              <div class="mt-3">
                <div class="row mb-3">
                  <div class="col-3">
                    <label for="name" class="form-label">
                      Program Owner
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    class="col-3"
                    v-if="
                      hasPermissions([
                        'increment-management-create',
                        'increment-management-edit',
                      ])
                    "
                  >
                    <v-select
                      :disabled="requestAssessorsLists.p_o_status == 1"
                      :options="employees"
                      label="name"
                      :clearable="false"
                      :selectable="
                        (options) => isSelectableProgramOwner(options)
                      "
                      v-model="requestAssessorsLists.p_o_id"
                      :class="{
                        'p-invalid':
                          v$.requestAssessorsLists.p_o_id.$error ||
                          errorFor('Program Owner'),
                      }"
                    >
                    </v-select>
                    <v-errors
                      :severErrors="errorFor('Program Owner')"
                      :vuelidateErrors="{
                        errors: v$.requestAssessorsLists.p_o_id.$errors,
                        value: 'Program Owner',
                      }"
                    ></v-errors>
                  </div>

                  <div
                    class="col-3"
                    v-else-if="hasPermissions(['increment-management-list'])"
                  >
                    <v-select
                      disabled
                      label="name"
                      :clearable="false"
                      v-model="requestAssessorsLists.p_o_id"
                    >
                    </v-select>
                  </div>

                  <div class="col-2">
                    <div
                      class="requestButtonStyle"
                      style="background-color: gray"
                    >
                      Request By
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-3">
                    <label for="name" class="form-label">
                      Department Head
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    class="col-3"
                    v-if="
                      hasPermissions([
                        'increment-management-create',
                        'increment-management-edit',
                      ])
                    "
                  >
                    <v-select
                      :disabled="requestAssessorsLists.d_h_status == 1"
                      :options="employees"
                      :selectable="
                        (options) => isSelectableDepartmentHead(options)
                      "
                      label="name"
                      :clearable="false"
                      v-model="requestAssessorsLists.d_h_id"
                      :class="{
                        'p-invalid':
                          v$.requestAssessorsLists.d_h_id.$error ||
                          errorFor('Department Head'),
                      }"
                    >
                    </v-select>
                    <v-errors
                      :severErrors="errorFor('Department Head')"
                      :vuelidateErrors="{
                        errors: v$.requestAssessorsLists.d_h_id.$errors,
                        value: 'Department Head',
                      }"
                    ></v-errors>
                  </div>

                  <div
                    class="col-3"
                    v-else-if="hasPermissions(['increment-management-list'])"
                  >
                    <v-select
                      disabled
                      label="name"
                      :clearable="false"
                      v-model="requestAssessorsLists.d_h_id"
                    >
                    </v-select>
                  </div>

                  <div class="col-2">
                    <div class="requestButtonStyle bg-blue">Checked By</div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-3">
                    <label for="name" class="form-label">
                      GM/AGM
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    class="col-3"
                    v-if="
                      hasPermissions([
                        'increment-management-create',
                        'increment-management-edit',
                      ])
                    "
                  >
                    <v-select
                      :disabled="requestAssessorsLists.gm_agm_status == 1"
                      :options="employees"
                      :selectable="(options) => isSelectableGmAgm(options)"
                      label="name"
                      :clearable="false"
                      v-model="requestAssessorsLists.gm_agm_id"
                      :class="{
                        'p-invalid':
                          v$.requestAssessorsLists.gm_agm_id.$error ||
                          errorFor('GM AGM'),
                      }"
                    >
                    </v-select>
                    <v-errors
                      :severErrors="errorFor('GM AGM')"
                      :vuelidateErrors="{
                        errors: v$.requestAssessorsLists.gm_agm_id.$errors,
                        value: 'GM AGM',
                      }"
                    ></v-errors>
                  </div>

                  <div
                    class="col-3"
                    v-else-if="hasPermissions(['increment-management-list'])"
                  >
                    <v-select
                      disabled
                      label="name"
                      :clearable="false"
                      v-model="requestAssessorsLists.gm_agm_id"
                    >
                    </v-select>
                  </div>

                  <div class="col-2">
                    <div class="requestButtonStyle bg-success opacity-50">
                      Approved By
                    </div>
                  </div>
                </div>

                <!-- <div class="row mb-3">
                  <div class="col-3">
                    <label for="name" class="form-label">
                      Related COO
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div class="col-3" v-if="hasPermissions(['increment-management-create', 'increment-management-edit'])">
                    <v-select
                    :disabled="requestAssessorsLists.increment_management_approvals && requestAssessorsLists.increment_management_approvals.r_coo_status == 1"
                      :options="employees"
                      :selectable="(options) => isSelectableRelatedCoo(options)"
                      label="name"
                      :clearable="false"
                      v-model="requestAssessorsLists.r_coo_id"
                      :class="{
                        'p-invalid':
                          v$.requestAssessorsLists.r_coo_id.$error ||
                          errorFor('Related COO'),
                      }"
                    >
                    </v-select>
                    <v-errors
                      :severErrors="errorFor('Related COO')"
                      :vuelidateErrors="{
                        errors: v$.requestAssessorsLists.r_coo_id.$errors,
                        value: 'Related COO',
                      }"
                    ></v-errors>
                  </div>

                  <div class="col-3" v-else-if="hasPermissions(['increment-management-list'])">
                    <v-select
                      disabled
                      label="name"
                      :clearable="false"
                      v-model="requestAssessorsLists.r_coo_id"
                    >
                    </v-select>
                  </div>

                  <div class="col-2">
                    <div class="requestButtonStyle bg-success opacity-75">
                      Approved By
                    </div>
                  </div>
                </div> -->

                <div class="row mb-3">
                  <div class="col-3">
                    <label for="name" class="form-label">
                      CHLO
                      <span class="text-danger">*</span>
                    </label>
                  </div>
                  <div
                    class="col-3"
                    v-if="
                      hasPermissions([
                        'increment-management-create',
                        'increment-management-edit',
                      ])
                    "
                  >
                    <v-select
                      :disabled="requestAssessorsLists.chlo_status == 1"
                      :options="employees"
                      :selectable="(options) => isSelectableChlo(options)"
                      label="name"
                      :clearable="false"
                      v-model="requestAssessorsLists.chlo_id"
                      :class="{
                        'p-invalid':
                          v$.requestAssessorsLists.chlo_id.$error ||
                          errorFor('CHLO'),
                      }"
                    >
                    </v-select>
                    <v-errors
                      :severErrors="errorFor('CHLO')"
                      :vuelidateErrors="{
                        errors: v$.requestAssessorsLists.chlo_id.$errors,
                        value: 'CHLO',
                      }"
                    ></v-errors>
                  </div>

                  <div
                    class="col-3"
                    v-else-if="hasPermissions(['increment-management-list'])"
                  >
                    <v-select
                      disabled
                      label="name"
                      :clearable="false"
                      v-model="requestAssessorsLists.chlo_id"
                    >
                    </v-select>
                  </div>

                  <div class="col-2">
                    <div class="requestButtonStyle bg-success">Approved By</div>
                  </div>
                </div>
              </div>

              <div
                class="row"
                v-if="
                  hasPermissions([
                    'increment-management-create',
                    'increment-management-edit',
                  ])
                "
              >
                <div class="col-md-12">
                  <div class="text-center mt-3 mb-3">
                    <router-link :to="{ name: 'increment-management-request' }">
                      <button type="button" class="btn w-sm btn-secondary">
                        Cancel
                      </button>
                    </router-link>
                    <button
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3 ms-3"
                      data-bs-toggle="tooltip"
                      style="cursor: not-allowed"
                      title="There is no grading user data."
                      v-if="gradingDetail.length == 0"
                    >
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                    <button
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3 ms-3"
                      data-bs-toggle="tooltip"
                      style="cursor: not-allowed"
                      title="This data is already approved."
                      v-else-if="requestAssessorsLists.chlo_status == 1"
                    >
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                    <button
                      v-else
                      :disabled="isLoading ? true : false"
                      type="button"
                      class="btn w-sm btn-success waves-effect waves-light px-3 ms-3"
                      @click="
                        currentRouteName ===
                        'increment-management-request-update'
                          ? updateIncrementRequest()
                          : createIncrementRequest()
                      "
                    >
                      <span
                        v-if="isLoading"
                        class="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                      ></span>
                      {{ isLoading == true ? "Loading..." : "Save" }}
                    </button>
                  </div>
                </div>

                <!-- end col -->
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { useToast } from "vue-toastification";
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import validationErrors from "../../../mixins/validationErrors";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import userHasPermissions from "../../../mixins/userHasPermissions";

export default {
  setup() {
    const toast = useToast();
    return { v$: useVuelidate(), toast };
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    flatPickr,
  },
  mixins: [validationErrors, userHasPermissions],
  data() {
    return {
      programs: [],
      employees: this.$store.getters["odoo/getAllEmployees"],
      batch: [],
      levels: [],
      effectiveDate: "",
      incrementData: {
        program_id: "",
        program_name: "",
        program_short_name: "",
        program_batch_id: "",
        program_batch_no: "",
        level: "",
      },
      requestAssessorsLists: {
        p_o_id: "",
        d_h_id: "",
        gm_agm_id: "",
        chlo_id: "",
      },
      gradingDetail: [],
      gradingDetailById: [],
      selectedProgram: null,
      selectedBatch: null,
      selectedLevel: null,
      existedData: [],
      loading: false,
      isLoading: false,
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlHRIS: process.env.VUE_APP_COURSIA_BASE_URL,
    };
  },
  validations() {
    return {
      selectedProgram: { required },
      selectedBatch: { required },
      selectedLevel: { required },
      effectiveDate: { required },
      requestAssessorsLists: {
        p_o_id: { required },
        d_h_id: { required },
        gm_agm_id: { required },
        chlo_id: { required },
      },
      incrementData: {
        program_id: { required },
        program_name: { required },
        program_short_name: { required },
        program_batch_id: { required },
        program_batch_no: { required },
        level: { required },
      },
    };
  },
  methods: {
    async getAllLevels() {
      this.loading = true;
      await axios
        .get(`${this.baseUrl}admin/v1/levels`)
        .then((response) => {
          this.levels = response.data.data;
        })
        .catch((error) => {
          this.error = error.response;
        });
      this.loading = false;
    },

    getSelectedProgram() {
      if (this.selectedProgram) {
        this.selectedLevel = null;
        this.selectedBatch = null;
        this.gradingDetail = [];
        this.incrementData.program_id = this.selectedProgram.id;
        this.incrementData.program_name = this.selectedProgram.name;
        this.incrementData.program_short_name = this.selectedProgram.short_name;
        this.getBatchByProgram(this.incrementData.program_id);
      }
    },
    getSelectedBatch() {
      if (this.selectedBatch) {
        this.selectedLevel = null;
        this.gradingDetail = [];
        this.incrementData.program_batch_id = this.selectedBatch.id;
        this.incrementData.program_batch_no = this.selectedBatch.batch_no;
        this.getAllLevels();
      }
    },

    getSelectedLevel() {
      if (this.selectedLevel) {
        this.gradingDetail = [];
        this.incrementData.level = this.selectedLevel.name;
        this.getGradingEvaluationData();
      }
    },

    async getBatchByProgram(program_id) {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(
          `${this.baseUrlHRIS}api/program-batches/list/?program_id=${program_id}`
        )
        .then((response) => {
          this.batch = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Batch!");
        });
    },

    async getPrograms() {
      this.loading = true;
      this.$Progress.start();
      await axios
        .get(`${this.baseUrlHRIS}api/programs`)
        .then((response) => {
          this.programs = response.data.data;
          this.loading = false;
          this.$Progress.finish();
        })
        .catch(() => {
          this.toast.error("Not Found Program!");
        });
    },

    async getGradingEvaluationData() {
      // await this.$store.getters["odoo/getAllEmployees"];
      if (!this.selectedLevel) return;
      this.employees = await this.$store.getters["odoo/getAllEmployees"];

      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/increment-management-request-getEvaluationData/${this.incrementData.program_id}/${this.incrementData.program_batch_id}/${this.incrementData.level}`
        )
        .then((response) => {
          this.gradingDetail = response.data.data;
          this.gradingDetail.forEach((item) => {
            this.employees.find((employee) => {
              if (
                employee.emp_id == item.grading_evaluation_detail.employee_id
              ) {
                item.business_unit = employee.business_unit[0];
                item.remark = "";
              }
            });
          });
        })
        .catch((error) => {
          this.error = error.response;
        });

      this.loading = false;
    },

    async createIncrementRequest() {
      const isFormCorrect = await this.v$.$validate();
      this.gradingDetail.forEach((item) => {
        if (!item.r_coo_id) return (item.r_c_error = true);
        else item.r_c_error = false;
      });
      if (!isFormCorrect) return;

      this.isLoading = true;

      const data = [];

      this.gradingDetail.forEach((item) => {
        data.push({
          grading_evaluation_id: item.id,
          p_o_id: this.requestAssessorsLists?.p_o_id?.emp_id,
          d_h_id: this.requestAssessorsLists?.d_h_id?.emp_id,
          gm_agm_id: this.requestAssessorsLists?.gm_agm_id?.emp_id,
          r_coo_id: item.r_coo_id?.emp_id,
          chlo_id: this.requestAssessorsLists?.chlo_id?.emp_id,
          remark: item.remark,
        });
      });
      const toSendData = {
        request_details: data,
        effective_date: this.effectiveDate,
      };
      await axios
        .post(
          `${this.baseUrl}admin/v2/increment-management-request`,
          toSendData
        )
        .then(() => {
          this.$router.push({ name: "increment-management-request" });
          this.toast.success("Request created successfully.");
        })
        .catch((error) => {
          this.error = error.response;
          //   console.log(this.error)
          this.toast.error(this.error.data.errors);
        });

      this.isLoading = false;
    },

    async getIncrementManagementRequestById() {
      this.loading = true;
      await axios
        .get(
          `${this.baseUrl}admin/v2/increment-management-request/${this.$route.params.id}`
        )
        .then(async (response) => {
          const data = response.data.data;
          if (data) {
            this.gradingDetailById = data;
            this.incrementData =
              data[0].grading_evaluations.grading_evaluation_detail;
            this.effectiveDate = data[0].effective_date;
            this.selectedProgram = {
              id: this.incrementData.program_id,
              name: this.incrementData.program_name,
            };
            this.selectedBatch = {
              id: this.incrementData.program_batch_id,
              batch_no: this.incrementData.program_batch_no,
            };
            this.selectedLevel = this.incrementData.level;

            this.requestAssessorsLists = {
              p_o_id: data[0].p_o_id,
              p_o_status: data[0].p_o_status,
              d_h_id: data[0].d_h_id,
              d_h_status: data[0].d_h_status,
              gm_agm_id: data[0].gm_agm_id,
              gm_agm_status: data[0].gm_agm_status,
              chlo_id: data[0].chlo_id,
              chlo_status: data[0].chlo_status,
            };
            this.getAssessorName();

            // await this.getGradingEvaluationData();

            data.forEach((item) => {
              this.employees.find((employee) => {
                if (employee.emp_id == item.r_coo_id) {
                  item.grading_evaluations.r_coo_id = employee;
                }
                if (
                  employee.emp_id ==
                  item.grading_evaluations.grading_evaluation_detail.employee_id
                ) {
                  item.grading_evaluations.business_unit =
                    employee.business_unit[0];
                  item.grading_evaluations.remark = item.remark;
                  item.grading_evaluations.r_coo_status = item.r_coo_status;
                }
              });
              this.gradingDetail.push(item.grading_evaluations);
            });
          }
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong");
        });
      this.loading = false;
    },

    async updateIncrementRequest() {
      const isFormCorrect = await this.v$.$validate();
      let isSave = true;
      this.gradingDetail.forEach((item) => {
        if (!item.r_coo_id) {
          isSave = false;
          return (item.r_c_error = true);
        } else item.r_c_error = false;
      });
      if (!isFormCorrect || !isSave) return;
      const data = [];

      this.isLoading = true;

      this.gradingDetail.forEach((item) => {
        data.push({
          grading_evaluation_id: item.id,
          p_o_id: this.requestAssessorsLists?.p_o_id?.emp_id,
          d_h_id: this.requestAssessorsLists?.d_h_id?.emp_id,
          gm_agm_id: this.requestAssessorsLists?.gm_agm_id?.emp_id,
          r_coo_id: item.r_coo_id?.emp_id,
          chlo_id: this.requestAssessorsLists?.chlo_id?.emp_id,
          remark: item.remark,
        });
      });
      const toSendData = {
        request_details: data,
        effective_date: this.effectiveDate,
      };
      await axios
        .put(
          `${this.baseUrl}admin/v2/increment-management-request/${this.$route.params.id}`,
          toSendData
        )
        .then(() => {
          this.$router.push({ name: "increment-management-request" });
          this.toast.success("Request updated successfully.");
        })
        .catch((error) => {
          this.error = error.response;
          //   console.log(this.error)
          this.toast.error("Something went wrong.");
        });

      this.isLoading = false;
    },

    getAssessorName() {
      const assessor = this.requestAssessorsLists;
      this.employees.forEach((employee) => {
        if (employee.emp_id == assessor.p_o_id) {
          assessor.p_o_id = {
            name: employee.name,
            emp_id: assessor.p_o_id,
          };
        }
        if (employee.emp_id == assessor.d_h_id) {
          assessor.d_h_id = {
            name: employee.name,
            emp_id: assessor.d_h_id,
          };
        }
        if (employee.emp_id == assessor.gm_agm_id) {
          assessor.gm_agm_id = {
            name: employee.name,
            emp_id: assessor.gm_agm_id,
          };
        }
        // if (employee.emp_id == assessor.r_coo_id) {
        //   assessor.r_coo_id = {
        //     name: employee.name,
        //     emp_id: assessor.r_coo_id,
        //   };
        // }
        if (employee.emp_id == assessor.chlo_id) {
          assessor.chlo_id = { name: employee.name, emp_id: assessor.chlo_id };
        }
      });
      console.log(assessor, "assessor");
    },

    async geteExistedData() {
      await axios
        .get(`${this.baseUrl}admin/v2/increment-management-request`)
        .then((response) => {
          this.existedData = response.data.data;
        })
        .catch((error) => {
          this.error = error.response;
          this.toast.error("Something went wrong");
        });
    },
    isSelectableProgramOwner(options) {
      if (!this.requestAssessorsLists.p_o_id || !options) return true;
      return this.requestAssessorsLists.p_o_id.emp_id != options.emp_id;
    },
    isSelectableDepartmentHead(options) {
      if (!this.requestAssessorsLists.d_h_id || !options) return true;
      return this.requestAssessorsLists.d_h_id.emp_id != options.emp_id;
    },
    isSelectableGmAgm(options) {
      if (!this.requestAssessorsLists.gm_agm_id || !options) return true;
      return this.requestAssessorsLists.gm_agm_id.emp_id != options.emp_id;
    },
    isSelectableChlo(options) {
      if (!this.requestAssessorsLists.chlo_id || !options) return true;
      return this.requestAssessorsLists.chlo_id.emp_id != options.emp_id;
    },
    isSelectableProgram(options) {
      if (!this.selectedProgram || !options) return true;
      return this.selectedProgram.id != options.id;
    },
    isSelectableBatch(options) {
      if (!this.selectedBatch || !options) return true;
      return this.selectedBatch.id != options.id;
    },
    isSelectable(options) {
      if (this.selectedLevel) {
        const existed = this.existedData.some(
          (item) =>
            item.program_id === this.selectedProgram.id &&
            item.program_batch_id == this.selectedBatch.id &&
            item.level == options.name
        );

        // If the batch doesn't exist, add it to existedData
        if (!existed) {
          this.existedData = this.existedData.filter(
            (item) => !item.is_selected
          );
          this.existedData.push({
            program_id: this.selectedProgram.id,
            program_batch_id: this.selectedBatch.id,
            level: this.selectedLevel.name,
            is_selected: true,
          });
        }
      }
      // Now, check if the options.id exists in existedData
      return !this.existedData.find(
        (item) =>
          item.program_id === this.selectedProgram.id &&
          item.program_batch_id == this.selectedBatch.id &&
          item.level == options.name
      );
      //  return (
      //     this.existedEmployee.find((employee) => employee.employee_id === options.employee_id && employee.level == this.presentation.level && employee.type == this.presentation.type ) === undefined
      //   );
    },
    // isSelectable(options) {
    //   return (
    //     this.existedData.find(
    //       (item) =>
    //         item.program_id === this.selectedProgram.id &&
    //         item.program_batch_id == this.selectedBatch.id &&
    //         item.level == options.name
    //     ) === undefined
    //   );
    // },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },

  async created() {
    if (this.currentRouteName == "increment-management-request-update") {
      await this.getIncrementManagementRequestById();
    }
    await this.getPrograms();
    this.geteExistedData();
    // console.log(this.employees)
  },
};
</script>

<style scoped>
.custom-selectCSS {
  --vs-dropdown-min-width: 160px;
  --vs-dropdown-max-height: 200px;
}
.custom-v-select .vs__dropdown-toggle .vs__open-indicator::before {
  display: none;
}
tbody,
td,
tfoot,
th,
thead,
tr {
  border-color: unset;
  border-style: unset;
  border-width: unset;
}
.requestButtonStyle {
  padding: 0.5em;
  /* background-color: gray; */
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  cursor: pointer;
  color: white;
  font-weight: bold;
}
</style>
